import { TextField } from "@kobalte/core";
import { Show, type Component, type Setter } from "solid-js";
import { twMerge } from "tailwind-merge";
import { StIcon } from "../icons";
import { TbMail } from "solid-icons/tb";

export const EmailField: Component<{
  inputProps?: TextField.TextFieldInputProps;
  labelProps?: TextField.TextFieldLabelProps;
  email: string;
  setEmail: (val: string) => void;
  withIcon?: boolean;
  label?: string;
  placeholder?: string;
}> = (props) => {
  return (
    <TextField.Root
      value={props.email}
      onChange={props.setEmail}
      class="flex flex-col gap-2 w-full"
    >
      <TextField.Label
        {...props.labelProps}
        class={twMerge(
          "text-slate-700 dark:text-indigo-200 font-medium",
          props.labelProps?.class,
        )}
      >
        {props.label || "Email"}
      </TextField.Label>

      <div class="w-full relative">
        <TextField.Input
          {...props.inputProps}
          type="email"
          required
          placeholder={props.placeholder || "your@email.com"}
          class={twMerge(
            "w-full placeholder:text-slate-600 dark:placeholder:text-slate-400 rounded px-3 py-1 border dark:bg-transparent dark:border-indigo-800 outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-indigo-800",
            props.inputProps?.class,
            props.withIcon && "pl-8",
          )}
        />

        <Show when={props.withIcon}>
          <div
            aria-hidden
            class="pointer-events-none absolute top-0 left-2 bottom-0 flex flex-col justify-center"
          >
            <StIcon
              class="text-slate-700 dark:text-slate-300 mt-[1px]"
              icon={TbMail}
            />
          </div>
        </Show>
      </div>
    </TextField.Root>
  );
};
